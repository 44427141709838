body::-webkit-scrollbar {
  display: none;
}
body {
  padding-right: 0px !important;
}

.swal2-actions {
  flex-direction: row !important;
  width: 100% !important;
  /* justify-content: space-between !important; */
  align-items: center !important;
}

.swal2-modal {
  padding: 30px 55px;
}

.popup-trust-images{
  object-fit: contain;
  object-position: center;
  width: "100%" !important;
}

.swal2-confirm {
  width: 180px;
  background-color: #ff8744 !important;
  box-shadow: 0px 0px 20px #00000033;
  font: normal normal bold 20px/20px noto sans !important;
  padding: 1rem 0;
}
.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-close:focus {
  outline: none;
  box-shadow: none !important;
}
.swal2-close{
  width: 0.9em;
  height: 0.9em;
  margin-top: 5px;
  margin-right: 5px;
  font-size:1.7em ;
  background-color: #ff8744;
  border-radius: 50%;
  color: #ffffff;
  :hover{
    background-color: #ff8744;
  }
}
.swal2-close:hover{
  color: #ffffff;
  background-color: #ff8744;
}
.swalButton {
  width: 150px;
  background-color: white !important;
  box-shadow: 0px 0px 20px #00000033;
  padding: 1rem 0;
  margin: 0.5rem 0;
  cursor: pointer;
  border-radius: 8px;
  font: normal normal 900 25px/49px Noto Sans;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.swalButton:hover {
  background-color: #ff8744 !important;
  color: #ffffff !important;
}

.swalButton a {
  text-decoration: none;
  font-weight: 900;
  color: #ff8744;
}
.swalButton:hover a {
  color: #ffffff;
}

/* 
.swal2-confirm:hover {
  background-color: white !important;
} */

.swal2-cancel:hover {
  background-color: white !important;
}

@media screen and (max-width: 600px) {
  .swal2-confirm {
    width: 100px;
    background-color: white !important;
    box-shadow: 0px 0px 16px #00000033;
    font: normal normal bold 20px/20px noto sans !important;
    padding: 1rem 0;
  }

  .swal2-cancel {
    width: 100px;
    background-color: white !important;
    box-shadow: 0px 0px 20px #00000033;
    font: normal normal bold 16px/20px noto sans !important;
    padding: 1rem 0;
  }
}

/* .swal2-backdrop-show {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, .2) !important;
} */

.auth-wrapper {
  height: 100vh;
  overflow: hidden;
}
.auth-inner{
  height: 100vh;
}


@media (min-width: 1200px) {
  .auth-cover .auth-card {
    width: 400px;
  }
  
@media (max-width: 575.98px) {
  .auth-wrapper.auth-cover .brand-logo {
    left: 1.5rem;
    padding-left: 0;
  }
}
.auth-wrapper .auth-bg {
  background-color: #ffffff;
}
}

